import { useCallback, useEffect, useRef, useState } from "react";



type Data = {
    dataset_id: string;
    metadata_fields: string[];
    metadata_values: string[];
} | undefined;

type ApiResponse = {
    result: Data;
    timedelta: number;
};

// type FetchQueryParams = {
// };

const PA = process.env.REACT_APP_API_ADDRESS || '';
let timeout: any = null;

let cached_data: Data;
let cached_data_last_updated_time: number;

export function useFetchMeta(dataset_id: string) {
    const timedelta = useRef(0);
    const [data, setData] = useState<Data>(cached_data);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const fetchMeta = useCallback(
        async () => {
            if (!dataset_id) {
                console.error("[useFetchMeta] dataset_id is undefined");
                return;
            }
            if (cached_data && ((Date.now() - cached_data_last_updated_time) < (30 * 1000)) ) {
                return;
            }
            // console.log('load a new data');
            timeout = setTimeout(() => {
                setIsLoading(true);
            }, 300);
    
            await fetch(
                `${PA}/v1/${dataset_id}/meta`,
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Ошибка при выполнении запроса");
                    }
                    return response.json();
                })
                .then((_data: ApiResponse) => {
                    setError("");
                    const pageContents = _data.result;
                    timedelta.current = _data.timedelta;
                    setData(pageContents);
                })
                .catch((error: Error) => {
                    setError(error.message);
                })
                .finally(() => {
                    setIsLoading(false);
                    clearTimeout(timeout);
                })
        },
        [dataset_id]
    );

    useEffect(() => {
        if (!data) return;
        cached_data = data;
        cached_data_last_updated_time = Date.now();
    }, [data]);

    return {
        data,
        isLoading,
        error,
        fetchMeta,
        timedelta,
    };
}