// import MarkdownModule from 'react-markdown';
// import remarkGfm from 'remark-gfm'
// import rehypeRaw from 'rehype-raw'
import { highlightSearchResultText } from '../apps/search/tools/highlightSearchResultText';

export const Markdown: React.FC<{ children: any, searchValue?: string }> = ({ children, searchValue }) => {
    if (!children) return null;

    // Функция для рендеринга текстовых узлов с подсветкой
    const renderers = {
        // p: ({ children: child, ...props }: any) => <p>{highlightSearchResultText(children, searchValue)}</p>,
        // li: ({ children: child, ...props }: any) => highlightSearchResultText(children, searchValue),
        // table: ({ children: child, ...props }: any) => (highlightSearchResultText(children, searchValue))
        pre: ({children: child, ...props}: any) => <p className="font-mono p-2 border text-xs rounded-md block">{highlightSearchResultText(child, searchValue)}</p>
    };

    return children;

    // return (
    //     <MarkdownModule
    //         components={renderers}
    //         remarkPlugins={[remarkGfm]}
    //         rehypePlugins={[rehypeRaw]}
    //         className="markdown text-sm flex flex-col gap-4"
    //         children={children}
    //     />
    // );
};
