import { useState } from "react";
import classNames from "classnames";
import pick from "lodash/pick";
import { highlightSearchResultText } from "../tools/highlightSearchResultText";

export const MetadataViewAsTags: React.FC<{
    data: any;
    searchValue?: string;
    showFullButton?: boolean;
    fieldsWhiteList?: string[];
}> = ({ data, searchValue, showFullButton, fieldsWhiteList = [] }) => {
    const [showFull, setShowFull] = useState(false);
    if (!data) return null;

    const list = showFull
        ? Object.keys(data)
        : Object.keys(
            pick(data, fieldsWhiteList)
        );

    return (
        <>

            <ul className={
                classNames(
                    "flex flex-row gap-1 flex-wrap text-xs font-medium my-0.5  text-[var(--enp-text-gray)]",
                    showFull && "flex-col items-start"
                )}
            >
                {list?.map((e, indx) => {
                    const value = data[e];
                    const key = e + indx;
                    return (
                        <li key={key} className="flex flex-col md:flex-row">
                            <span className="rounded px-1 border border-[var(--enp-tag-border)] bg-[var(--enp-tag-background)]">
                                {showFull ? (
                                    <span className="font-light">{e}: </span>
                                ) : ''}
                                {highlightSearchResultText(value, searchValue)}
                            </span>
                        </li>
                    );
                })}
                {showFullButton && (
                    <button onClick={() => setShowFull(!showFull)}>
                        {showFull ? "hide" : "show"} full metadata
                    </button>
                )}
            </ul>

        </>
    );
};
