import { useEffect } from "react";
import classNames from "classnames";
import { useFetchOverview } from "./useFetchOverview";
import useRoveFocus from "./useRoveFocus";
import RoveFocusItem from "./RoveFocusItem";
import flatMap from "lodash/flatMap";
import uniq from "lodash/uniq";

// const OverviewSection = ({
//     data,
//     label,
//     className,
//     ulClassName,
//     liClassName,
//     buttonClassName = "px-2 py-1",
//     onItemPress,
// }: {
//     data: string[];
//     label?: string;
//     className?: string;
//     ulClassName?: string;
//     liClassName?: string;
//     buttonClassName?: string;
//     onItemPress?: (text: string) => void;
// }) => {
//     const onPress = useCallback((value: string) => {
//         if (onItemPress) {
//             onItemPress(value)
//         }
//     }, [onItemPress]);

//     return (
//         <div className={className}>
//             {label && (
//                 <p className="text-sm font-medium mb-2 opacity-60">{label}</p>
//             )}
//             <ul className={classNames("flex flex-wrap gap-2", ulClassName)}>
//                 {data.map((e, index) => {
//                     return (
//                         // <RoveFocusItem
//                         //     key={e}
//                         //     focus={(focus-prevLength) === index}
//                         //     index={index}
//                         //     setFocus={setFocus}

//                         // >
//                         <li key={e} className={liClassName}>
//                             <button
//                                 type="button"
//                                 className={classNames(
//                                     "rounded border border-[var(--enp-border-color)] font-medium",
//                                     buttonClassName,
//                                 )}
//                                 onClick={() => onPress(e)}
//                             >
//                                 {e}
//                             </button>
//                         </li>
//                         // </RoveFocusItem>
//                     );
//                 })}
//             </ul>
//         </div>
//     );
// };

type Props = {
    dataset_id: string;
    onItemPress?: (text: string) => void;
    isInputInFocus?: boolean;
    inputRef?: any;
};

export const OverviewView: React.FC<Props> = ({ onItemPress, dataset_id, isInputInFocus, inputRef }) => {
    const { isLoading, data, error, fetchOverview } = useFetchOverview(dataset_id);


    // const keys = data ? Object.keys(data) : [];
    const flatData = uniq(flatMap(Object.values(data || {}).map(e => e.data)));
    // let dataLength = 0;
    // Object.keys(data || []).forEach((key) => {
    //     dataLength += 1;
    //     if (!data) return;
    //     dataLength += (data as any)[key]?.data?.length || 0;
    // });
    // console.log("data len", dataLength)

    const roveFocusLength = flatData.length || 0;
    const { focus, setFocus, resetFocus } = useRoveFocus(roveFocusLength, {
        didFocusOnStartAgain: () => {
            inputRef?.current?.focus?.();
        }
    });

    useEffect(() => {
        fetchOverview();
    }, []);

    useEffect(() => {
        resetFocus();
    }, [isInputInFocus])

    return (
        <div className="text-[var(--enp-text-gray)] p-4">
            {isLoading && (
                <h2 className="mb-6 font-semibold">
                    <span className="font-semibold">Loading...</span>
                </h2>
            )}
            {error && JSON.stringify(error, null, 4)}
            <div className="flex flex-col gap-8">
                {/* {keys.map((key, index) => {
                    const value = (data as any)[key];
                    if (!value) return null;

                    return (
                        <OverviewSection
                            key={key + index}
                            // className="md:max-w-[50%]"
                            label={value.title}
                            data={value.data}
                            onItemPress={onItemPress}
                            buttonClassName={index === 0 ? "px-5 py-3 rounded-lg bg-background2" : undefined}
                        />
                    )
                }
                )} */}
                <ul className={classNames(
                    "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2"
                    // "flex flex-wrap gap-2"
                )}>
                    {flatData.map((e, index) => {
                        return (
                            <li key={e} className={classNames(
                                // "w-full h-full",
                            )}>
                                <RoveFocusItem
                                    key={e}
                                    focus={focus === index}
                                    index={index}
                                    setFocus={setFocus}

                                >
                                    <button
                                        type="button"
                                        className={classNames(
                                            "rounded-xl font-base",
                                            "px-4 py-2 w-full h-full",
                                            "outline-none focus:ring-2 focus:ring-[var(--enp-search-input-ring-color)]",
                                            "hover:bg-background2 border border-transparent hover:border-[var(--enp-border-color)]"
                                        )}
                                        onClick={() => {
                                            resetFocus();
                                            onItemPress?.(e);
                                        }}
                                    >
                                        {String(e)}
                                    </button>
                                </RoveFocusItem>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
