import { toggleTheme } from "@enapter/design-theme"
import classNames from "classnames";
import { ReactNode } from "react";

export const ToggleTheme = ({
    className,
    label = "Toggle Dark/Light Theme"
}: { className?: string; label?: ReactNode }) => {
    return (
        <button className={classNames("text-sm text-textGray", className)} onClick={() => toggleTheme()} >
            {label}
        </button>
    )
}