import { useCallback, useEffect, useRef, useState } from "react";
import { DataItem, ResultsView } from "./components/ResultsView";
import SearchForm from "./components/SearchForm";
import { useDetectClickOutside } from "./components/useDetectClickOutside";

// import "./colorschemes/first_color_scheme.css";
import "./colorschemes/second_color_scheme.css";
import { OverviewView } from "./components/OverviewView";

import { ToggleTheme } from "../../theme/ToggleTheme";
import { ResultsViewOld } from "./components/ResultsViewOld";
// import useRoveFocus from "./components/useRoveFocus";
import classNames from "classnames";
import { useFetchSearch } from "./components/useFetchSearch";
import { pluralize } from "./tools/pluralize";
import { useFetchMeta } from "./components/useFetchMeta";
import useRoveFocus from "./components/useRoveFocus";
import { SuggestionSection } from "./components/SuggestionSection";
import { analytics_event } from "./components/analytics";

const __DEV__ = !process.env.REACT_APP_ENV;

const DEFAULT_CONFIG_UI = {
    show_metadata: false,
    show_full_metadata_button: false,

    default_view_id: "basic",
    show_view_headers_button: false,
    show_view_raw_button: false,
    show_view_collapsed_button: false,

    show_full_match_button: false,
    show_both_match_button: false,
    show_filter_button: false,
    time_delta_visible: false,
    enable_content_markdown: false,
    show_mega_precise_button: false,
    show_metadata_whitelist: [],
}

export const SearchApp: React.FC<{ config?: any; onItemPress?: (item: any) => void; itemPressLabel?: string }> = ({ config, onItemPress, itemPressLabel }) => {

    const isHeadersAvailable = (config?.ui?.show_view_headers_button ?? DEFAULT_CONFIG_UI.show_view_headers_button);
    const isRawViewAvailable = (config?.ui?.show_view_raw_button ?? DEFAULT_CONFIG_UI.show_view_raw_button);
    const isAdvancedViewAvailable = config?.ui?.show_view_collapsed_button ?? DEFAULT_CONFIG_UI.show_view_collapsed_button;
    const isTimedeltaVisible = config?.ui?.time_delta_visible ?? DEFAULT_CONFIG_UI.time_delta_visible;

    const configUI = { ...DEFAULT_CONFIG_UI, ...(config?.ui || {}) };

    const isContentMarkdownEnabled =
        config?.ui?.enable_content_markdown ?? DEFAULT_CONFIG_UI.enable_content_markdown;

    const dataset_id = config?.dataset?.id;

    const [isFilterEnabled, setFilterEnabled] = useState(false);
    const [isFullMatchEnabled, setFullMatchEnabled] = useState(false);
    const [isBothMatchEnabled, setBothMatchEnabled] = useState(false);
    const [isMetadataVisible, setMetadataVisibility] = useState(false);
    const [isInputInFocus, setInputInFocus] = useState(false);

    const [view, setView] = useState<string>(
        // localStorage.getItem('enpsearch_view') ||
        (configUI?.default_view_id ?? DEFAULT_CONFIG_UI.default_view_id)
    );

    const [searchText, setSearchText] = useState("");
    const [isVisible, setVisible] = useState(false);

    const resultsContainerRef = useRef<HTMLDivElement>(null);

    const setClose = () => {
        setVisible(false);
    };

    const setMegaPreciseEnabledChanged = useCallback((e: boolean) => {
        setBothMatchEnabled(e);
        setFullMatchEnabled(e);
    }, []);

    const setOpen = useCallback(() => {
        // Note: setTimeout fix for useDetectClickOutside autoclose...
        setTimeout(() => {
            setVisible(true);
        }, 1);
    }, []);

    const wrapperRef = useDetectClickOutside({
        onTriggered: setClose,
        onOpenTriggered: setOpen,
        triggerKeys: ["Escape"],
    });

    const {
        data: _data,
        isLoading,
        error,
        fetchSearch,
        timedelta,
        setData,
    } = useFetchSearch({ dataset_id });

    const { data: dataMeta, fetchMeta } = useFetchMeta(dataset_id);

    const data: DataItem[] | undefined = _data;




    useEffect(() => {
        fetchMeta()
    }, []);

    useEffect(() => {
        resultsContainerRef.current?.scrollTo(0, 0)
    }, [data])


    useEffect(() => {
        if (searchText) {
            fetchSearch({
                text: searchText,
                limit: searchText.length < 2 ? 30 : 1999,
                filterEnabled: isFilterEnabled,
                fullMatchEnabled: isFullMatchEnabled,
                bothMatchEnabled: isBothMatchEnabled,
                isGroupped: view !== 'raw',
            });
        } else {
            setData(undefined)
        }
    }, [searchText]);

    useEffect(() => {
        fetchSearch({
            text: searchText,
            limit: (searchText?.length || 0) < 2 ? 30 : 1999,
            filterEnabled: isFilterEnabled,
            fullMatchEnabled: isFullMatchEnabled,
            bothMatchEnabled: isBothMatchEnabled,
            isGroupped: view !== 'raw',
            ignoreCache: true
        });
    }, [isFilterEnabled, isFullMatchEnabled, isBothMatchEnabled])


    useEffect(() => {

        (window as any).ENAPTER_SEARCH_OPEN = setOpen;

    }, [setOpen]);

    useEffect(() => {
        const body = window.document.getElementsByTagName("body")[0];
        if (isVisible) {
            body.setAttribute("style", "overflow: hidden");
        } else {
            body.removeAttribute("style");
        }
    }, [isVisible]);

    const setCachedView = async (value: any) => {
        // await fetchSearch({
        //     text: searchText,
        //     limit: (searchText?.length || 0) < 2 ? 30 : 1999,
        //     filterEnabled: isFilterEnabled,
        //     fullMatchEnabled: isFullMatchEnabled,
        //     bothMatchEnabled: isBothMatchEnabled,
        //     isGroupped: value !== 'raw',
        //     ignoreCache: true,
        // });


        setView(value)
        localStorage.setItem("enpsearch_view", value);
        await analytics_event(value === "advanced" ? 'set_view_advanced' : 'set_view_basic');
    }

    const foundTitleAfter = isTimedeltaVisible ? `, ${Number(timedelta.current).toFixed(2)} seconds for request` : "";

    const suggestion = {
        filter: dataMeta?.metadata_fields || config?.suggestion?.metadata_fields || [],
        query: dataMeta?.metadata_values || config?.suggestion?.metadata_values || [],
    }

    const dics = isFilterEnabled ? (suggestion?.filter || []) : (suggestion?.query || []);
    const NU = isFilterEnabled ? 2 : 3;
    const suggest_function = (searchText?.length || 0) < NU ? 'startsWith' : 'includes';
    const SEARCH_SUGGEST_ITEMS_COUNT = 3;
    let suggest = isFilterEnabled ? dics : dics.filter((elem: any) => {
        if (!searchText && isFilterEnabled) return true;
        return !!searchText && (elem.toLowerCase())?.[suggest_function](searchText.toLowerCase()) && (searchText.toLowerCase() !== elem.toLowerCase());
    }).slice(0, SEARCH_SUGGEST_ITEMS_COUNT);

    suggest = isFilterEnabled ? dics.map((e: any) => `${e}: `) : suggest;

    const inputRef = useRef<HTMLInputElement>(null);
    const roveFocusLength = data?.length + suggest?.length || 0;
    const { focus, setFocus, resetFocus } = useRoveFocus(roveFocusLength, {
        didFocusOnStartAgain: () => {
            inputRef.current?.focus()
        },
    });
    useEffect(() => {
        setTimeout(() => {
            resetFocus();
        })
    }, [view, searchText, isInputInFocus]);

    return (
        <>
            {__DEV__ && (
                <div className="relative flex flex-col px-9 gap-4 justify-center items-center h-full bg-background1 min-h-[300px]">
                    {/* <p className="bg-background2 -mt-2 w-24 h-24 border border-border flex justify-center items-center rounded-full">
                        <img src="/android-chrome-512x512.png" className="w-16" />
                    </p> */}
                    <div className="w-full relative max-w-[480px]">
                        <input
                            onFocus={setOpen}
                            placeholder={`Search in ${config?.dataset?.display_name}`}
                            className="outline-none pl-10 rounded-xl bg-background2 border border-border px-3 py-2 pr-0 w-full"
                        />
                        <span className="absolute left-3 top-[10px] material-icons opacity-50">search</span>
                    </div>
                    <ToggleTheme className="absolute right-0 top-0 opacity-80 hover:opacity-100" label={(
                        <span title="Toggle Theme" className="material-icons-outlined p-8 ">palette</span>
                    )} />
                </div>
            )}
            {isVisible && (
                <main className="bg-[var(--enp-backdrop-background)] absolute z-[999] left-0 right-0 bottom-0 top-0 overflow-hidden text-[var(--enp-text)]">
                    <div
                        ref={wrapperRef}
                        className="relative h-full md:min-h-[320px] md:h-auto overflow-hidden max-w-[var(--enp-container-width)] md:top-[8vh] md:max-h-[85vh] rounded-2xl border border-[var(--enp-border-color)] mx-auto flex flex-col bg-background2"
                    >
                        <div className="w-full flex-grow  flex flex-col overflow-hidden">
                            <header className="w-full relative lg:container mx-auto flex flex-col flex-shrink-0 bg-[var(--enp-header-background)] px-[var(--enp-header-padding-x)]">
                                <div className=" w-full pt-4 flex flex-col sm:flex-row items-stretch sm:items-center">
                                    <SearchForm
                                        ref={inputRef}
                                        filterEnabled={isFilterEnabled}
                                        onFilterChanged={setFilterEnabled}
                                        defaultValue={searchText}
                                        onReset={() => setSearchText("")}
                                        handleSearch={setSearchText}
                                        onClose={setClose}
                                        fullMatchEnabled={isFullMatchEnabled}
                                        onFullMatchEnabledChanged={setFullMatchEnabled}
                                        bothMatchEnabled={isBothMatchEnabled}
                                        onBothMatchEnabledChanged={setBothMatchEnabled}
                                        onMegaPreciseEnabledChanged={setMegaPreciseEnabledChanged}
                                        // suggestion={suggestion}
                                        placeholderLabel={config?.dataset?.display_name}
                                        ui={configUI}
                                        isInputInFocus={isInputInFocus}
                                        setInputInFocus={setInputInFocus}
                                    />
                                </div>
                                <div className="mb-4">
                                    <SuggestionSection
                                        onItemPress={(elem) => {
                                            if (searchText?.includes(elem) && isFilterEnabled) return;
                                            setSearchText(isFilterEnabled ? `${elem} ${searchText}` : elem)
                                            if (isFilterEnabled) {
                                                setTimeout(() => {
                                                    const _index = elem.length;
                                                    inputRef.current?.setSelectionRange(_index, _index);

                                                }, 100)
                                            }
                                            resetFocus()
                                        }}
                                        suggest={suggest}
                                        isFilterEnabled={isFilterEnabled}
                                        searchValue={searchText}
                                        focus={focus}
                                        setFocus={setFocus}
                                    />
                                </div>
                            </header>
                            <hr className="border-top border-[var(--enp-border-color)]" />

                            <div ref={resultsContainerRef} className="flex-grow px-[var(--enp-result-container-padding-x)] py-2 overflow-auto enp-scroll bg-[var(--enp-result-container-background)]">

                                {data ? (
                                    <div className="text-xs mb-2 h-10 flex justify-between items-center">
                                        <p>
                                            <span className="font-medium">Found</span>{" "}
                                            <span className="text-[var(--enp-text-gray)] text-xs">
                                                {isLoading
                                                    ? "loading..."
                                                    : data ? `${pluralize(data.length, 'record')}${foundTitleAfter}` : ''}
                                            </span>
                                        </p>

                                        <div className="flex items-center gap-1">
                                            {view === "raw" && (
                                                <button
                                                    className="text-[var(--enp-text-gray)] mr-1"
                                                    onClick={() => setMetadataVisibility(!isMetadataVisible)}
                                                >
                                                    {isMetadataVisible ? "hide" : "show"} metadata
                                                </button>
                                            )}

                                            {(isRawViewAvailable || isAdvancedViewAvailable) && [
                                                isRawViewAvailable ? {
                                                    icon: 'table_rows',
                                                    id: 'raw',
                                                    label: 'Raw',
                                                } : null,
                                                isHeadersAvailable ? {
                                                    icon: 'table_chart',
                                                    id: 'basic',
                                                    label: 'Basic',
                                                } : null,
                                                isAdvancedViewAvailable ? {
                                                    icon: 'inventory_2',
                                                    id: 'advanced',
                                                    label: 'Advanced',
                                                } : null,
                                            ].map(item => item && (
                                                <button
                                                    key={item.id}
                                                    onClick={() => {
                                                        setCachedView(item.id as any);
                                                    }}
                                                    className={classNames(
                                                        "text-[var(--enp-text-gray)] text-xs flex justify-center items-center rounded-full p-2 py-1",
                                                        "hover:bg-backgroundObjectHover",
                                                        view === item.id && "text-text bg-backgroundObjectActive"
                                                    )}
                                                >

                                                    <span>{item.label}</span>
                                                </button>
                                            ))}
                                        </div>

                                    </div>
                                ) : null}
                                {(data) ? (
                                    view === "raw" ? (
                                        <ResultsViewOld
                                            focus={focus}
                                            setFocus={setFocus}
                                            filterEnabled={isFilterEnabled}
                                            fullMatchEnabled={isFullMatchEnabled}
                                            dataset_id={dataset_id}
                                            searchParamValue={searchText}
                                            isMetadataVisible={isMetadataVisible}
                                            data={data}
                                            isLoading={isLoading}
                                            error={error}
                                            onItemPress={onItemPress}
                                            itemPressLabel={itemPressLabel}
                                        />
                                    ) : (
                                        <ResultsView
                                            focus={focus - (suggest?.length || 0)}
                                            setFocus={setFocus}
                                            view={view}
                                            data={data}
                                            error={error}
                                            isLoading={isLoading}
                                            filterEnabled={isFilterEnabled}
                                            fullMatchEnabled={isFullMatchEnabled}
                                            dataset_id={dataset_id}
                                            isContentWithMarkdown={isContentMarkdownEnabled}
                                            searchParamValue={searchText}
                                            ui={configUI}
                                            onItemPress={onItemPress}
                                            itemPressLabel={itemPressLabel}
                                        />
                                    )
                                ) : !isFilterEnabled && (
                                    <OverviewView
                                        dataset_id={dataset_id}
                                        onItemPress={(v) => setSearchText(v)}
                                        inputRef={inputRef}
                                        isInputInFocus={isInputInFocus}
                                    />
                                )}
                            </div>

                            {/* <footer>
                            123
                        </footer> */}
                        </div>
                    </div>
                </main>
            )}
        </>
    );
};
