import { highlightSearchResultText } from "../tools/highlightSearchResultText";

export const MetadataView: React.FC<{ data: any, searchValue?: string }> = ({ data, searchValue }) => {
    if (!data) return null;

    const list = Object.keys(data);

    return (
        <ul className="flex flex-col gap-1">
            {list.map((e, indx) => {
                const value = data[e];
                const key = e+indx;
                return (
                    <li key={key} className="flex flex-col md:flex-row">
                        <span className="md:max-w-[120px] md:w-full">{e}</span>
                        <span>{highlightSearchResultText(value, searchValue)}</span>
                    </li>
                )
            })}
        </ul>
    )

}