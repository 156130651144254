import escapeRegExp from 'lodash/escapeRegExp';

export const highlightSearchResultText = (
  value: string,
  searchValue?: string,
  classNameForHighlight: string = "text-textLinkActive rounded font-bold"
): JSX.Element | null => {
  if (!value) return null;

  if (!searchValue || !searchValue.trim()) {
    return <>{value}</>;
  }

  const searchWords = searchValue.trim().split(/\s+/).map(word => escapeRegExp(word));
  const regex = new RegExp(searchWords.join('|'), 'gi');

  const highlightedText: JSX.Element[] = [];
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(value)) !== null) {
    const matchStart = match.index;
    const matchEnd = match.index + match[0].length;

    // Add non-matched text before the match
    if (matchStart > lastIndex) {
      highlightedText.push(
        <span key={`plain-${lastIndex}-${matchStart}`}>
          {value.substring?.(lastIndex, matchStart)}
        </span>
      );
    }

    // Add the matched text
    highlightedText.push(
      <span key={`highlight-${matchStart}-${matchEnd}`} className={classNameForHighlight}>
        {value.substring?.(matchStart, matchEnd)}
      </span>
    );

    lastIndex = matchEnd;
  }

  // Add any remaining text after the last match
  if (lastIndex < value.length) {
    highlightedText.push(
      <span key={`final-plain-${lastIndex}`}>
        {value.substring?.(lastIndex)}
      </span>
    );
  }

  return <>{highlightedText}</>;
};
