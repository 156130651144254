import classNames from "classnames";
import RoveFocusItem from "./RoveFocusItem";
import { highlightSearchResultText } from "../tools/highlightSearchResultText";

type Props = {
    isFilterEnabled?: boolean;
    searchValue?: string;
    suggest?: any;

    focus?: any;
    setFocus?: any;
    onItemPress?: (item: string) => void;
}

export const SuggestionSection: React.FC<Props> = ({
    suggest,
    searchValue,
    isFilterEnabled,
    onItemPress,

    focus,
    setFocus
}) => {
    if (!suggest?.length) return null;

    return (
        <div className="mt-2 text-sm font-medium text-[var(--enp-text-gray)]">
            <ul className=" overflow-auto border border-[var(--enp-search-input-border-color)] w-full rounded-xl">
                {suggest?.map((elem: any, index: number) => (
                    <RoveFocusItem
                        key={elem}
                        focus={focus === index}
                        index={index}
                        setFocus={setFocus}
                        >
                        <button
                            key={elem}
                            className={classNames(
                                "w-full text-left p-1.5 px-2 flex gap-2 items-center outline-none",
                                "border-b border-[var(--enp-search-input-border-color)] last:border-b-0 outline-none",
                                "focus:border-b-transparent focus:rounded-xl focus:ring-inset focus:ring-2 focus:ring-[var(--enp-search-input-ring-color)] focus:bg-[var(--enp-search-input-background)] focus:text-[var(--enp-text-gray-hover-color)]"
                            )}
                            onClick={() => onItemPress?.(elem)}
                        >
                            <span className="material-icons-outlined opacity-90 text-md text-gray1">{isFilterEnabled ? "new_label" : "search"}</span>
                            <span>
                                {highlightSearchResultText(elem, searchValue)}
                            </span>
                        </button>
                    </RoveFocusItem>
                ))}
            </ul>
        </div>
    )
}