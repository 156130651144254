const PA = process.env.REACT_APP_API_ADDRESS || '';

export const onResultsItemClicked = async ({ element_id, title, source_url }: any) => {
    await fetch(
        `${PA}/v1/common/event/result_clicked/?element_id=${element_id}&title=${title}&source_url=${source_url}`,
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Response is not ok for <onResultsItemClicked>`);
            }
            return response.json();
        })
        .catch((error: Error) => {
            // ignore
        })
}
