// import { useEffect, useState } from "react";
import { Markdown } from "../../../components/Markdown";
import { highlightSearchResultText } from "../tools/highlightSearchResultText";
import { MetadataView } from "./MetadataView";
// import { useFetchSearch } from "./useFetchSearch";
import { ResultsViewProps } from "./ResultsView";
import RoveFocusItem from "./RoveFocusItem";

export const ResultsViewOld = ({
    searchParamValue,
    renderEmpty,
    isContentWithMarkdown,
    isLoading,
    data,
    error,
    isMetadataVisible,
    onItemPress,
    itemPressLabel = "Add",
    focus,
    setFocus,
    renderInRoveFocusItem,
}: ResultsViewProps) => {

    // const [isMetadataVisible, setMetadataVisibility] = useState(false);
    const resultParamShowMetadata = isMetadataVisible;

    if (isLoading) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
            </div>
        );
    }

    if (!data || !searchParamValue) {
        if (renderEmpty) {
            return renderEmpty;
        }

        return <></>;
    }

    const shouldRenderItemAsLink = !onItemPress;

    return (
        <>
            {data?.length ? (
                <ul className="flex flex-col gap-4 whitespace-pre-wrap break-all">
                    {data?.map((_e, index) => {
                        const e = Array.isArray(_e) ? _e[0] : _e;
                        // const score = Array.isArray(_e) ? _e[1] : undefined;
                        // console.log("e.page_content", e.page_content)
                        const headerTitle = e.metadata.catalog_level_0 && (e.metadata.title !== e.metadata.catalog_level_0)
                            ? `${e.metadata.catalog_level_0} · ${e.metadata.title}`
                            : e.metadata.title

                        return (
                            <RoveFocusItem
                                key={e.page_content + Number(Math.random() * 100000).toFixed(0)}
                                focus={focus === index}
                                index={index}
                                setFocus={setFocus}>
                                {renderInRoveFocusItem}
                                <a
                                    href={e.metadata.source_url}
                                    // className="hover:text-blueHover"
                                    className="focus:ring-2 focus:ring-borderFocus ring-offset-4 ring-offset-background1 rounded outline-none"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <li
                                        className="px-4 py-3 border border-[var(--enp-result-item-border)] bg-[var(--enp-result-item-background)] rounded-xl"
                                    >
                                        {!!headerTitle && (
                                            <h3 className="mb-2 text-xl font-medium flex  items-start">
                                                {!e.metadata.source_url ? (
                                                    headerTitle
                                                ) : (
                                                    <>
                                                        {highlightSearchResultText(
                                                            headerTitle,
                                                            searchParamValue
                                                        )}
                                                    </>
                                                )}
                                                {onItemPress && (
                                                    <button
                                                        onClick={() => onItemPress && onItemPress(e)}
                                                        className="bg-[var(--enp-item-button-background)] text-[var(--enp-item-button-text-color)] active:bg-green px-3 py-1 rounded-lg text-sm min-h-10 min-w-16">
                                                        {itemPressLabel}
                                                    </button>
                                                )}
                                            </h3>
                                        )}
                                        {e.metadata?.catalog_level_1 && (
                                            <p className="mb-2">
                                                ·{" "}
                                                {highlightSearchResultText(
                                                    e.metadata.catalog_level_1,
                                                    searchParamValue
                                                )}
                                            </p>
                                        )}
                                        {e.metadata?.catalog_level_2 && (
                                            <p className="mb-2">
                                                ·{" "}
                                                {highlightSearchResultText(
                                                    e.metadata.catalog_level_2,
                                                    searchParamValue
                                                )}
                                            </p>
                                        )}
                                        {e.metadata?.catalog_level_3 && (
                                            <p className="mb-2">
                                                ·{" "}
                                                {highlightSearchResultText(
                                                    e.metadata.catalog_level_3,
                                                    searchParamValue
                                                )}
                                            </p>
                                        )}
                                        {e.metadata?.catalog_level_4 && (
                                            <p className="mb-2">
                                                ·{" "}
                                                {highlightSearchResultText(
                                                    e.metadata.catalog_level_4,
                                                    searchParamValue
                                                )}
                                            </p>
                                        )}
                                        <div title="content" className="mb-2 text-sm markdown">
                                            {isContentWithMarkdown ? (
                                                <Markdown searchValue={searchParamValue}>
                                                    {e.page_content}
                                                </Markdown>
                                            ) : (
                                                highlightSearchResultText(e.page_content, searchParamValue)
                                            )}
                                        </div>
                                        <div
                                            title="metadata"
                                            className="text-xs text-[var(--enp-text-gray)]"
                                        >
                                            {resultParamShowMetadata && (
                                                <MetadataView
                                                    data={e.metadata}
                                                    searchValue={searchParamValue}
                                                />
                                            )}
                                        </div>
                                    </li>
                                </a>
                            </RoveFocusItem>
                        );
                    })}
                </ul>
            ) : (
                <div>
                    Unfortunately, nothing is found.
                    <br /> Please supplement your request with additional information.
                </div>
            )}
        </>
    );
};
