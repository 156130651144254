
import { useCallback, useState, useEffect } from "react";
import { analytics_event } from "./analytics";

type UseRoveFocusOptions = {
    didFocusOnStartAgain?: () => void;
}

function useRoveFocus(size: number, options: UseRoveFocusOptions = {}) {
    const [blocked, setBlocked] = useState(false);
    const [currentFocus, setCurrentFocus] = useState<number>(-1);
    const { didFocusOnStartAgain } = options;

    const resetFocus = useCallback(() => {
        setCurrentFocus(-1);
    }, []);

    const handleKeyDown = useCallback(
        async (e: any) => {
            if (e.keyCode === 40) {
                if (blocked) {
                    setBlocked(false);
                }
                // Down arrow
                e.preventDefault();
                setCurrentFocus(currentFocus === size - 1 ? 0 : currentFocus + 1);
                await analytics_event('keyboard_arrow_down');
            } else if (e.keyCode === 38) {
                // Up arrow
                e.preventDefault();
                const isInStart = currentFocus === 0;
                if (isInStart) {
                    if (didFocusOnStartAgain && !blocked) {
                        setBlocked(true);
                        setCurrentFocus(-1);
                        didFocusOnStartAgain();
                    } else {
                        setCurrentFocus(size - 1);
                    }
                } else {
                    if (blocked) return;
                    setCurrentFocus(currentFocus - 1);
                }
                await analytics_event('keyboard_arrow_up');
            }
        },
        [size, blocked, currentFocus, setCurrentFocus, didFocusOnStartAgain]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown, false);
        return () => {
            document.removeEventListener("keydown", handleKeyDown, false);
        };
    }, [handleKeyDown]);

    return { focus: currentFocus, setFocus: setCurrentFocus, resetFocus };
}

export default useRoveFocus;