const PA = process.env.REACT_APP_API_ADDRESS || '';

export const analytics_event = async (event_id: string) => {
    await fetch(
        `${PA}/v1/common/event/${event_id}`,
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Response is not ok for <analytics_event: ${event_id}>`);
            }
            return response.json();
        })
        .catch((error: Error) => {
            // ignore
        })
}