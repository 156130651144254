/* eslint-disable react-hooks/rules-of-hooks */
import { cloneElement, useCallback, useEffect, useRef } from 'react'


const RoveFocusItem = ({ focus, children, index, setFocus, ...otherProps }: React.PropsWithChildren<any>) => {
    const ref = useRef<any>(null);

    useEffect(() => {
        if (focus) {
            // Move element into view when it is focused
            ref.current?.focus();
        }
    }, [focus, ref]);

    const handleSelect = useCallback(() => {
        // setting focus to that element when it is selected
        setFocus(index);
    }, [index, setFocus]);

    return cloneElement(children, {
        ref,
        tabIndex: focus ? 0 : -1,
        role: "button",
        ...otherProps,
        // onClick: handleSelect,
        onKeyPress: handleSelect,
    });
};


export default RoveFocusItem;