import ReactDOM from "react-dom/client";

// import "@enapter/design-tokens/colors.css";
// import "@enapter/design-tokens/typography.css";

import "./index.css";
import "./theme/theme.css";
import "./theme/tooltip.css";

import { SearchApp } from "./apps/search/SearchApp";
import { toggleTheme, initTheme, setThemeLight, setThemeDark } from "@enapter/design-theme";

const __DEV__ = !process.env.REACT_APP_ENV

export function initEnapterSearch(config: any, default_theme?: any) {
  const searchContainer = document.createElement("div");
  searchContainer.id = "enpsearch";
  const appContainer = document.getElementById("app");
  if (appContainer) {
    appContainer.append(searchContainer);
  } else {
    document.body.append(searchContainer);
  }

  const root = ReactDOM.createRoot(
    document.getElementById("enpsearch") as HTMLElement
  );

  initTheme({ elementId: "enpsearch" });
  (window as any).ENAPTER_SEARCH_TOGGLE_THEME = toggleTheme;
  (window as any).ENAPTER_SEARCH_SET_THEME_LIGHT = setThemeLight;
  (window as any).ENAPTER_SEARCH_SET_THEME_DARK = setThemeDark;

  root.render(
    <SearchApp
      config={config}
      // onItemPress={onItemPress}
      // itemPressLabel={itemPressLabel}
    />)
    ;
}

(window as any).ENAPTER_INIT_SEARCH = initEnapterSearch;

if (__DEV__) {
  window.onload = () => {
    const DEFAULT_CONFIG = require("./_configs/temp-enpsearch.config.json");
    (window as any).ENAPTER_INIT_SEARCH(DEFAULT_CONFIG);
  }
}

