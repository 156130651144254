import React, {
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
} from "react";
import debounce from "lodash/debounce";
import classNames from "classnames";

interface SearchFormProps {
    ref?: any;
    defaultValue?: string;
    handleSearch?: (value: string) => void;
    filterEnabled?: boolean;
    placeholderLabel?: string;
    onFilterChanged?: (value: boolean) => void;
    fullMatchEnabled?: boolean;
    onFullMatchEnabledChanged?: (value: boolean) => void;
    bothMatchEnabled?: boolean;
    onMegaPreciseEnabledChanged?: (value: boolean) => void;
    onBothMatchEnabledChanged?: (value: boolean) => void;
    onClose?: () => void;
    onReset?: () => void;
    ui?: {
        show_full_match_button?: boolean;
        show_both_match_button?: boolean;
        show_filter_button?: boolean;
        show_mega_precise_button?: boolean;
    };

    isInputInFocus?: boolean;
    setInputInFocus?: (e: boolean) => void;
}

const SearchForm: React.FC<SearchFormProps> = forwardRef<HTMLInputElement, SearchFormProps>(({
    defaultValue,
    handleSearch,
    placeholderLabel = 'docs',
    onClose,
    onReset,
    filterEnabled,
    onFilterChanged,
    fullMatchEnabled,
    bothMatchEnabled,
    onMegaPreciseEnabledChanged,
    onBothMatchEnabledChanged,
    onFullMatchEnabledChanged,
    ui,
    isInputInFocus,
    setInputInFocus,
}, inputRef) => {

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!handleSearch) return;
            handleSearch(e.target.value);
        },
        [handleSearch]
    );

    const debouncedResults = useMemo(() => {
        return debounce(handleInputChange, 350);
    }, [handleInputChange]);

    const handleReset = useCallback(() => {
        if (onReset) {
            onReset();
        }

        if (inputRef && 'current' in inputRef && inputRef.current) {
            inputRef.current.value = "";
            inputRef.current.focus?.();
        }
    }, [onReset, inputRef]);

    const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    }, [debouncedResults]);

    useEffect(() => {
        if (inputRef && 'current' in inputRef && inputRef.current) {
            inputRef.current.value = defaultValue || "";
            inputRef.current.focus?.();
            // resetFocus();
        }
    }, [defaultValue, inputRef]);

    const FilterButton = ({
        iconName = "format_quote",
        tooltipText = "full match",
        onClick = () => { },
        isButtonEnabled = false,
    }) => {
        return (
            <span className="w-full tooltip">
                <button
                    type="button"
                    onClick={onClick}
                    className={classNames(
                        "flex w-auto items-center p-3 border border-[var(--enp-search-input-border-color)] justify-center text-[var(--enp-text-gray)] rounded-2xl",
                        "outline-none focus:ring-2 focus:ring-[var(--enp-search-input-ring-color)]",
                        isButtonEnabled &&
                        "bg-[var(--enp-search-input-background)] text-[var(--enp-text-gray-hover-color)]"
                    )}
                >
                    <span
                        className={classNames("w-[24px] overflow-hidden material-icons-outlined opacity-90")}
                    >
                        {iconName}
                    </span>
                </button>
                <span className="tooltiptext tooltiptext-b">{tooltipText}</span>
            </span>
        )
    }

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit} className="flex flex-grow md:items-center relative">
                <div className="flex w-full gap-2 md:gap-0">
                    <div className="mb-1.5 md:mb-0 flex flex-grow w-full items-center">
                        <div className="flex flex-grow items-center gap-3">
                            <input
                                ref={inputRef}
                                autoFocus
                                type="text"
                                placeholder={
                                    filterEnabled
                                        ? "title: faq   firmware_version: 3.5.0"
                                        : fullMatchEnabled ? `Search full match in ${placeholderLabel}` : `Search in ${placeholderLabel}`
                                }
                                defaultValue={defaultValue}
                                onChange={debouncedResults}
                                className={classNames(
                                    "text-md bg-[var(--enp-search-input-background)] border border-[var(--enp-search-input-border-color)] outline-none focus:ring-2 focus:ring-[var(--enp-search-input-ring-color)] placeholder:text-[var(--enp-search-input-placeholder)] font-medium rounded-2xl py-3 pl-4 w-full",
                                    defaultValue && "pr-11"
                                )}
                                onFocus={() => setInputInFocus?.(true)}
                                onBlur={() => setInputInFocus?.(false)}
                            />
                        </div>
                        {!!defaultValue && (
                            <div className="-ml-12">
                                <button
                                    type="button"
                                    onClick={handleReset}
                                    className="flex items-center p-3 justify-center text-[var(--enp-text-gray)] rounded-full"
                                >
                                    <span className="material-icons-outlined">close</span>
                                </button>
                            </div>
                        )}
                    </div>
                    {ui?.show_mega_precise_button || ui?.show_full_match_button || ui?.show_both_match_button || ui?.show_filter_button && (
                        <div className="md:ml-2 flex flex-grow gap-1 md:gap-2">
                            {!filterEnabled && (
                                <>
                                    {ui?.show_mega_precise_button && (
                                        <FilterButton
                                            iconName="format_quote"
                                            tooltipText="full match"
                                            isButtonEnabled={fullMatchEnabled}
                                            onClick={() => {
                                                if (onMegaPreciseEnabledChanged) {
                                                    onMegaPreciseEnabledChanged(!fullMatchEnabled);
                                                }
                                            }}
                                        />
                                    )}
                                    {ui?.show_full_match_button && (
                                        <FilterButton
                                            iconName="format_quote"
                                            tooltipText="full text match"
                                            isButtonEnabled={fullMatchEnabled}
                                            onClick={() => {
                                                if (onFullMatchEnabledChanged) {
                                                    onFullMatchEnabledChanged(!fullMatchEnabled);
                                                }
                                            }}
                                        />

                                    )}
                                    {ui?.show_both_match_button && (
                                        <FilterButton
                                            iconName="checklist_rtl"
                                            tooltipText="match more precisely"
                                            isButtonEnabled={fullMatchEnabled}
                                            onClick={() => {
                                                if (onBothMatchEnabledChanged) {
                                                    onBothMatchEnabledChanged(!bothMatchEnabled);
                                                }
                                            }}
                                        />

                                    )}
                                </>
                            )}
                            {ui?.show_filter_button && (
                                <span className="w-full tooltip">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            if (onFilterChanged) {
                                                onFilterChanged(!filterEnabled);
                                            }
                                        }}
                                        className={classNames(
                                            "w-full flex md:w-auto items-center p-1 md:p-3 border border-[var(--enp-search-input-border-color)] justify-center text-[var(--enp-text-gray)] rounded-2xl",
                                            "outline-none focus:ring-2 focus:ring-[var(--enp-search-input-ring-color)]",
                                            filterEnabled &&
                                            "bg-[var(--enp-search-input-background)] text-[var(--enp-text-gray-hover-color)]"
                                        )}
                                    >
                                        <span className={classNames("w-[24px] overflow-hidden material-icons-outlined opacity-90")}>
                                            data_object
                                        </span>
                                    </button>
                                    <span className="tooltiptext tooltiptext-lb">filter by fields</span>
                                </span>
                            )}
                        </div>
                    )}

                </div>
                <div className="ml-4 md:hidden">
                    <button
                        onClick={onClose}
                        type="button"
                        className="text-[var(--enp-text-gray)] py-[14px]"
                    >
                        Close
                    </button>
                </div>
            </form>
        </div>
    );
});

export default SearchForm;
