import { useCallback, useRef, useState } from "react";

type ApiResponse = {
    result: {
        page_content: string; // Строка JSON, которую мы будем парсить отдельно
        metadata: {
            code: string;
            dataset: string;
            dataset_last_updated_display: string;
            dataset_last_updated_timestamp: number;
            device_model: string;
            device_name: string;
            device_object: string;
            firmware_build: string;
            firmware_version: string;
            manifest_url: string;
            product_efuse: string;
            product_uid: string;
            title: string;
        };
        type: string;
    }[];
    timedelta: number;
};

type FetchQueryParams = {
    text?: string;
    dataset?: string;
    k?: number;
    r?: string;
    sp?: string;
    limit?: number;
    filterEnabled?: boolean;
    fullMatchEnabled?: boolean;
    bothMatchEnabled?: boolean;
    ignoreCache?: boolean;
    isGroupped?: boolean;
    cb?: (key: any) => void;
};

const PA = process.env.REACT_APP_API_ADDRESS || '';
let timeout: any = null;

let last_cached_query: string;

export function useFetchSearch(options: any = {}) {
    const { dataset_id } = options;

    const timedelta = useRef(0);
    const [data, setData] = useState<any[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const fetchSearch = useCallback(
        async ({ text, limit = 30, filterEnabled, ignoreCache, fullMatchEnabled, bothMatchEnabled, isGroupped, cb }: FetchQueryParams) => {
            if (!text) return;
            if (!ignoreCache && text === last_cached_query) return;
            if (!dataset_id) {
                console.error("[useFetchSearch] dataset_id is undefined");
                return;
            }
            
            timeout = setTimeout(() => {
                setIsLoading(true);
            }, 60);
            await fetch(
                `${PA}/v1/${dataset_id}/search/?q=${text}&limit=${limit}${isGroupped ? '&groupped=1' : ''}${filterEnabled ? '&filter=1' : ''}${fullMatchEnabled ? '&full_match=1' : ''}${bothMatchEnabled ? '&both_match=1' : ''}`,
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Ошибка при выполнении запроса");
                    }
                    return response.json();
                })
                .then((_data: ApiResponse) => {
                    setError("");
                    const pageContents = _data.result;
                    timedelta.current = _data.timedelta;
                    setData(pageContents);
                    last_cached_query = text;

                    if (cb) {
                        cb(_data)
                    }
                })
                .catch((error: Error) => {
                    setError(error.message);
                })
                .finally(() => {
                    clearTimeout(timeout);
                    setIsLoading(false);
                })
        },
        [dataset_id]
    );

    return {
        data,
        isLoading,
        error,
        fetchSearch,
        timedelta,
        setIsLoading,
        setData,
    };
}