import { useState } from "react";
import { analytics_event } from "./analytics";


export const Collapsed: React.FC<
    React.PropsWithChildren & {
        renderVisibleChildren: (isVisible: boolean) => React.ReactNode;
        count?: number;
        defaultValue?: boolean;
    }
> = ({ children, renderVisibleChildren, count, defaultValue = false }) => {
    const [isVisible, setVisible] = useState(defaultValue);

    const toggleVisible = () => {
        if (isVisible) {
            analytics_event("click_quotes_hide")
        } else {
            analytics_event("click_quotes_show")
        }

        setVisible(!isVisible);
    };

    return (
        <>
            <div className="flex flex-col md:flex-row">
                <div className="flex-grow">{renderVisibleChildren(isVisible)}</div>
                <div className="flex-shrink-0 w-full md:w-auto md:min-w-[100px] md:max-w-[180px] p-3">
                    <button
                        title={`${isVisible ? "Hide" : "Show"} full list of results`}
                        onClick={toggleVisible}
                        className="group flex items-center justify-center h-12 w-full ring-1 md:ring-0  hover:ring-1 ring-[var(--enp-border-color)] hover:bg-background1Hover rounded-xl pl-4 pr-2"
                    >
                        <span className="text-xs text-[var(--enp-text-gray)] text-right break-keep">
                            <span className="md:hidden">show{" "}</span>
                            {count} {count === 1 ? "quote" : "quotes"}
                        </span>
                        <i className="material-icons-outlined text-[var(--enp-text-gray)]">
                            {isVisible ? "expand_less" : "expand_more"}
                        </i>
                    </button>
                </div>
            </div>
            {isVisible && children}
        </>
    );
};
